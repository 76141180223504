import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/view',
    name: 'view',
    component: () => import('@/views/view')
  },

]

const router = new VueRouter({
  routes
})

// 全局前置守卫,路由在真正跳转之前执行此函数
// router.beforeEach((to, from, next) => {

// })

export default router
